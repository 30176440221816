import './index.module.scss';
import Layout from '../../../../layout';
import Header from '../../../../components/header/header';
import Footer from '../../../../components/footer/footer';
import ContactForm from '../../../../components/contact-form/contact-form';
import BannerInner from '../../../../components/banner-inner/banner-inner';
import ContentInner from '../../../../components/content-inner/content-inner';
/* eslint-disable-next-line */
export interface IndexProps {}

export function Index(props: IndexProps) {
  return (
    <Layout>
      <Header />
      <BannerInner
        caption={
          <h2 className="x-large-heading color-white">
            Insurance and Tax Strategies
          </h2>
        }
      />
      <ContentInner
        heading={'Insurance and Tax Strategies'}
        content={
          <p>
            While DGM is neither an accountant nor insurance broker, we
            understand the risks that occur when these two subjects are
            neglected. For more than twenty years we’ve helped our clients look
            at their lives and their businesses from every angle - always making
            sure that issues relating to insurance and taxation are properly
            addressed. We have relationships with trusted partners in these
            areas and will make sure you have the right advice for your life’s
            needs in those areas.
            <br />
            <br />
            As for tax strategies, we do everything we can to minimize taxable
            issues in the investment portfolios through the best use of
            retirement vehicles such as IRAs and SEPs, and we work hand in hand
            with a client’s accountant to take the appropriate tax losses or
            gains to maximize what stays in your pocket. Similarly we manage
            401k plans with strategies to maximize distributions to further
            reduce taxable events Everything we do is geared towards using a
            “tax-friendly” strategy, no matter what your station in life is.
            <br />
            <br />
            Insurance is the fabric that lets you sleep comfortably at night.
            It’s critical to have the right combination of insurance for both
            your personal and business dealing. We start by reviewing your
            overall financial picture and assess whether you have enough
            insurance to meet your needs. We also assess what types of insurance
            you may be deficient in; do you need key-man insurance for your
            business? Have enough disability insurance relative to your income?
            What wealth transfer strategies can be optimized to ensure the
            maximum amount stays in your family? These are all issues we and our
            trusted partners can advise on.
          </p>
        }
      />
      <ContactForm />
      <Footer />
    </Layout>
  );
}

export default Index;
